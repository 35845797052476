import _sortBy from "lodash/sortBy"

import { Scenario } from "../../../types.gen"
import { useMemo } from "react"

export const useScenarioLists = (
  scenarios: Scenario[]
): {
  primaryScenario: Scenario
  liveScenarios: Scenario[]
  resolvedScenarios: Scenario[]
  allLiveScenarios: Scenario[]
} => {
  const scenariosSorted = useMemo(() => {
    return _sortBy(scenarios, ["dateCreated"]).reverse()
  }, [scenarios])

  const unresolvedScenarios = useMemo(() => {
    return scenariosSorted.filter(scenario => !scenario.resolution)
  }, [scenariosSorted])

  const primaryScenario = unresolvedScenarios[0]
  const otherLiveScenarios = useMemo(() => {
    return unresolvedScenarios.slice(1)
  }, [unresolvedScenarios])
  const resolvedScenarios = useMemo(() => {
    return scenariosSorted.filter(scenario => !!scenario.resolution)
  }, [scenariosSorted])

  return {
    primaryScenario,
    liveScenarios: otherLiveScenarios,
    allLiveScenarios: unresolvedScenarios,
    resolvedScenarios,
  }
}