import _find from "lodash/find"
import { Scenario, ScenarioOption } from "../../../types.gen";

export const getCorrectScenarioOption = (scenario: Scenario): Maybe<ScenarioOption> => {
  if (scenario.resolution && scenario.resolution.optionId) {
    const option = _find(scenario.options, ['id', scenario.resolution.optionId])
    if (option) {
      return option
    }
  }
  return null
}