import React from "react"
import "./ScenarioResolution.css"

import { PredictionSpread, ScenarioOption } from "../../../../types.gen"
import { getCorrectPredictionSpread } from "../../utils"

getCorrectPredictionSpread

interface ScenarioResolutionProps {
  resolvedOptionId: string
  resolvedOptionTitle: string
  predictionSpread: Maybe<PredictionSpread>
  predictedOptionId: Maybe<string>
}

export const ScenarioResolution = ({
  resolvedOptionId,
  resolvedOptionTitle,
  predictionSpread,
  predictedOptionId,
}: ScenarioResolutionProps) => {
  const isCorrect = resolvedOptionId === predictedOptionId
  const { predictionCount: correctAnswers } = getCorrectPredictionSpread(
    predictionSpread,
    resolvedOptionId
  )
  const totalPredictions = predictionSpread
    ? predictionSpread.totalPredictionCount
    : 0
  const percentageCorrect = Math.round(
    ((correctAnswers || 0) / (totalPredictions || 1)) * 100
  )

  return (
    <div className="ScenarioResolution">
      <div>
        The correct answer was: <strong>{resolvedOptionTitle}</strong>
      </div>
      {predictedOptionId && (
        <>
          {isCorrect ? (
            <>
              <div>Great work, your prediction was correct!</div>
            </>
          ) : (
            <>
              <div>Better luck next time!</div>
            </>
          )}
        </>
      )}
      <small>
        {percentageCorrect}% of respondents predicted this correctly.
      </small>
    </div>
  )
}
