import { useMemo, useEffect, useCallback, useState, useRef } from "react"
import _find from "lodash/find"
import createPersistedState from "use-persisted-state"

import { Scenario, UserPrediction, PredictionSpread } from "../../../types.gen";
import { useScenarioState } from "../state";
import { useAuthState } from "../../../features/Auth/state";
import { ScenarioService } from "../services";

type ScenarioPersistedState = { [key: string]: { selectedOptionId: Maybe<string>, predictionConfidence: number } }
const useScenarioPersistedState = createPersistedState("scenarioState")

const defaultScenarioState = {
  selectedOptionId: null,
  predictionConfidence: 0.5,
}

interface UseScenario {
  isFetchingScenario: boolean
  isFetchingPrediction: boolean
  isFetchingPredictionSpread: boolean
  isCreatingPrediction: boolean
  selectedOptionId: string
  scenarioData: Maybe<Scenario>
  prediction: Maybe<UserPrediction>
  predictionSpread: Maybe<PredictionSpread>
  predictionConfidence: number
  predictionError: Maybe<Error>
  onChangePredictionConfidence: (confience: number) => void
  onSelectOption: (scenarioOptionId: Maybe<string>) => void
  onMakePrediction: () => Promise<void>
}

export const useScenario = (scenarioId: string): UseScenario => {
  const [
    scenarioState,
    setScenarioState,
  ] = useScenarioPersistedState<ScenarioPersistedState>({
    [scenarioId]: {
      ...defaultScenarioState,
    },
  })

  const { initialising } = useAuthState()
  const {
    scenarioListState: {
      isFetchingList: isFetchingScenarioList,
      isFetchingListItem: isFetchingScenarioListItem,
      scenarios,
      onFetchScenario
    },
    predictionListState: {
      isFetchingList: isFetchingPredictionList,
      isFetchingListItem: isFetchingPredictionListItem,
      predictions,
      onFetchPredictionForScenario,
    },
    predictionSpreadListState: {
      isFetchingListItem: isFetchingPredictionSpreadListItem,
      predictionSpreads,
      onFetchPredictionSpreadForScenario
    }
  } = useScenarioState()

  const isFetchingScenario = isFetchingScenarioList || isFetchingScenarioListItem[scenarioId];
  const isFetchingPrediction = isFetchingPredictionList || isFetchingPredictionListItem[scenarioId]
  const isFetchingPredictionSpread = isFetchingPredictionSpreadListItem[scenarioId]

  const scenarioStateRef = useRef(scenarioState)
  scenarioStateRef.current = scenarioState

  const selectedOptionId = scenarioState[scenarioId] ? scenarioState[scenarioId].selectedOptionId : null
  const predictionConfidence = scenarioState[scenarioId] ? scenarioState[scenarioId].predictionConfidence : 0.5

  const setScenarioStateItem = useCallback((key, value) => {
    setScenarioState({
      [scenarioId]: {
        ...scenarioStateRef.current[scenarioId],
        [key]: value,
      }
    })
  }, [setScenarioState, scenarioId, scenarioStateRef])

  const setSelectedOptionId = useCallback((selectedOptionId: Maybe<string>) => {
    setScenarioStateItem("selectedOptionId", selectedOptionId)
  }, [setScenarioStateItem])

  const setPredictionConfidence = useCallback((confidence: number) => {
    setScenarioStateItem("predictionConfidence", confidence)
  }, [setScenarioStateItem])

  const [isCreatingPrediction, setIsCreatingPrediction] = useState(false)
  const [predictionError, setPredictionError] = useState<Maybe<Error>>(null)

  const scenario = useMemo(() => {
    return _find(scenarios, ['id', scenarioId])
  }, [scenarios])

  const prediction = useMemo(() => {
    return _find(predictions, ['scenario.id', scenarioId])
  }, [predictions])

  const predictionSpread = useMemo(() => {
    return _find(predictionSpreads, ['scenarioId', scenarioId])
  }, [predictionSpreads])

  const isResolved = scenario ? !!scenario.resolution : false

  useEffect(() => {
    if (!initialising && !scenario && !isFetchingScenario) {
      onFetchScenario(scenarioId)
    }
  }, [initialising, scenario, isFetchingScenario])

  useEffect(() => {
    if (!!prediction || isResolved) {
      if (!predictionSpread && !isFetchingPredictionSpread) {
        onFetchPredictionSpreadForScenario(scenarioId)
      }
    }
  }, [prediction, isResolved, predictionSpread, isFetchingPredictionSpread, onFetchPredictionSpreadForScenario])

  const onSelectOption = useCallback((scenarioOptionId: Maybe<string>): void => {
    setSelectedOptionId(scenarioOptionId || null)
  }, [setSelectedOptionId])

  const onChangePredictionConfidence = useCallback((confidence: number) => {
    setPredictionConfidence(confidence)
  }, [setPredictionConfidence])

  const onMakePrediction = useCallback(async (): Promise<void> => {
    setIsCreatingPrediction(true)
    setPredictionError(null)
    if (selectedOptionId) {
      try {
        await ScenarioService.createPrediction(scenarioId, selectedOptionId, predictionConfidence)
        await Promise.all([
          onFetchPredictionForScenario(scenarioId, true),
          onFetchPredictionSpreadForScenario(scenarioId, true)
        ])
        setScenarioState({
          [scenarioId]: {
            ...defaultScenarioState,
          }
        })
      } catch (err) {
        setPredictionError(err)
      }
      setIsCreatingPrediction(false)
    }
  }, [scenarioId, selectedOptionId, predictionConfidence, setIsCreatingPrediction, setScenarioState])

  return {
    isFetchingScenario: initialising || isFetchingScenario,
    isFetchingPrediction: initialising || isFetchingPrediction,
    isFetchingPredictionSpread: initialising || isFetchingPredictionSpread,
    isCreatingPrediction,
    selectedOptionId: (prediction ? prediction.scenarioOption.id : selectedOptionId) || '',
    scenarioData: scenario || null,
    prediction: prediction || null,
    predictionSpread: predictionSpread || null,
    predictionConfidence: (prediction ? prediction.confidence : predictionConfidence) || 0.5,
    predictionError,
    onChangePredictionConfidence,
    onSelectOption,
    onMakePrediction
  }
}