import { useMemo } from "react"
import _find from "lodash/find"

import { Scenario, ScenarioCategory } from "../../../types.gen";
import { useScenarioState } from "../state";
import { useAuthState } from "../../../features/Auth/state";

interface UseCategory {
  isFetching: boolean
  categoryData: Maybe<ScenarioCategory>
  categoryScenarios: Scenario[]
}

export const useCategory = (categoryId: string): UseCategory => {
  const { initialising } = useAuthState()
  const {
    scenarioListState: {
      isFetchingList: isFetchingScenarioList,
      scenarios,
    },
    categoryListState: {
      isFetchingList: isFetchingCategoryList,
      categories,
    },
  } = useScenarioState()

  const category = useMemo(() => {
    return _find(categories, ["id", categoryId])
  }, [categories])

  const categoryScenarios = useMemo(() => {
    return scenarios.filter(s => s.category.id === categoryId)
  }, [scenarios])

  return {
    isFetching: initialising || isFetchingScenarioList || isFetchingCategoryList,
    categoryData: category || null,
    categoryScenarios: categoryScenarios,
  }
}