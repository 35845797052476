import _find from "lodash/find"
import { PredictionSpread, PredictionSpreadOption, ScenarioResolution } from "../../../types.gen"

export const getCorrectPredictionSpread = (
  predictionSpread: Maybe<PredictionSpread>,
  resolvedOptionId: string
): PredictionSpreadOption => {
  if (predictionSpread) {
    const correctOption = _find(predictionSpread.options, [
      "id",
      resolvedOptionId,
    ])
    if (correctOption) {
      return correctOption
    }
  }
  return {
    id: resolvedOptionId,
    predictionCount: 0,
  }
}

export const getPredictionSpreadForOption = (optionId: string, predictionSpread: Maybe<PredictionSpread>): Maybe<PredictionSpreadOption> => {
  if (predictionSpread) {
    const option = _find(predictionSpread.options, ["id", optionId])
    if (option) {
      return option
    }
  }
  return null
}

export const getOptionPercentageOfTotalPredictions = (optionId: string, predictionSpread: Maybe<PredictionSpread>): number => {
  const predictionSpreadForOption = getPredictionSpreadForOption(optionId, predictionSpread)
  const totalPredictions = predictionSpread
    ? predictionSpread.totalPredictionCount
    : 0
  const percentageOfTotal = predictionSpreadForOption
    ? Math.round(
      ((predictionSpreadForOption.predictionCount || 0) /
        totalPredictions) *
      100
    )
    : 0
  return percentageOfTotal
}

export const isCorrectOption = (optionId: string, resolution: Maybe<ScenarioResolution>): boolean => {
  if (resolution) {
    return resolution.optionId === optionId;
  }
  return false
}