import React from "react"
import "./CategoryList.css"

import { ScenarioCategory } from "../../../../types.gen"
import { CategoryBadge } from "../CategoryBadge"

interface CategoryListProps {
  categories: ScenarioCategory[]
}

export const CategoryList = ({
  categories,
}: CategoryListProps): JSX.Element => {
  return (
    <div className="CategoryList">
      {categories.map(category => {
        return (
          <div className="CategoryListItem" key={category.id}>
            <CategoryBadge {...category} />
          </div>
        )
      })}
    </div>
  )
}
