import React from "react"

import { CategoryBadge } from "../CategoryBadge"
import { Scenario } from "../../../../types.gen"

interface ScenarioHeroDisplayProps {
  scenario: Scenario
  hideCategory?: boolean
}

export const ScenarioHeroDisplay = ({
  scenario,
  hideCategory = false,
}: ScenarioHeroDisplayProps) => {
  const description: string[] = scenario.description
    ? scenario.description.split("\n")
    : [""]
  return (
    <div>
      {!hideCategory && (
        <h2>
          <CategoryBadge {...scenario.category} />
        </h2>
      )}
      <h1>{scenario.title}</h1>
      {description.map((d, index) => (
        <p key={index}>{d}</p>
      ))}
    </div>
  )
}
