import React from "react"
import { Link } from "gatsby"
import "./CategoryBadge.css"

interface CategoryBadgeProps {
  id: string
  title: string
}

export const CategoryBadge = ({
  id,
  title,
}: CategoryBadgeProps): JSX.Element => {
  return (
    <Link className="CategoryBadge" to={`/category/${id}`}>
      <span>{title}</span>
    </Link>
  )
}
