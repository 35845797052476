import React, { Fragment, useState, useCallback, useEffect } from "react"
import _find from "lodash/find"
import clsx from "clsx"
import "./ScenarioOptions.css"

import {
  ScenarioOption,
  PredictionSpread,
  ScenarioResolution,
  UserPrediction,
} from "../../../../types.gen"
import { AnalyticsService, CustomEvent } from "../../../../lib/analytics"
import { Button, Modal, TextLink, RangeSlider } from "../../../../components"
import { getOptionPercentageOfTotalPredictions } from "../../utils"

interface ScenarioOptionsProps {
  options: ScenarioOption[]
  isAnswerable: boolean
  resolution: Maybe<ScenarioResolution>
  prediction: Maybe<UserPrediction>
  isSubmittingPrediction: boolean
  isFetchingPredictionSpread: boolean
  predictionError: Maybe<Error>
  predictedOptionId: Maybe<string>
  predictionSpread: Maybe<PredictionSpread>
  predictionConfidence: number
  onChangePredictionConfidence: (confidence: number) => void
  onSelectOption: (optionId: Maybe<string>) => void
  onCreatePrediction: () => void
}

export const ScenarioOptions = ({
  options,
  isAnswerable,
  resolution,
  prediction,
  isSubmittingPrediction,
  predictionError,
  predictedOptionId,
  predictionSpread,
  predictionConfidence,
  onChangePredictionConfidence,
  onSelectOption,
  onCreatePrediction,
}: ScenarioOptionsProps) => {
  const defaultModalOpen = !!predictedOptionId && !prediction
  const [isModalOpen, setIsModalOpen] = useState(defaultModalOpen)
  const predictedOption = _find(options, ["id", predictedOptionId])
  const hasMadePrediction = !!prediction

  const onSelectOptionFn = useCallback(
    (optionId: string) => {
      setIsModalOpen(true)
      onSelectOption(optionId)
      AnalyticsService.logEvent(CustomEvent.SelectScenarioOutcomeOption)
    },
    [setIsModalOpen, onSelectOption]
  )

  const onClearSelection = useCallback(() => {
    setIsModalOpen(false)
    onSelectOption(null)
  }, [setIsModalOpen, onSelectOption])

  useEffect(() => {
    if (hasMadePrediction) {
      setIsModalOpen(false)
    }
  }, [hasMadePrediction, setIsModalOpen])

  const canAnswer = isAnswerable ? !resolution : false

  return (
    <div>
      <div className={clsx("ScenarioOptions", { resolved: !!resolution })}>
        {options.map(opt => {
          const isSelected = predictedOptionId === opt.id
          const isDisabled = isSubmittingPrediction || hasMadePrediction
          const percentageOfTotal = getOptionPercentageOfTotalPredictions(
            opt.id,
            predictionSpread
          )

          const buttonText = (
            <>
              <span>{opt.title}</span>
              {!!predictionSpread && (
                <span className="spread">({percentageOfTotal}%)</span>
              )}
            </>
          )

          return (
            <Fragment key={opt.id}>
              {!canAnswer ? (
                <Button disabled primary={isSelected}>
                  {buttonText}
                </Button>
              ) : (
                <Button
                  onClick={() => onSelectOptionFn(opt.id)}
                  disabled={isDisabled}
                  primary={isSelected}
                >
                  {buttonText}
                </Button>
              )}
            </Fragment>
          )
        })}
      </div>
      {prediction && prediction.confidence && (
        <p>
          You answered with {Math.round(prediction.confidence * 100)}%
          confidence.
        </p>
      )}
      <Modal isOpen={isModalOpen} onRequestClose={onClearSelection}>
        <div className="ScenarioPredictionDetail">
          {predictedOption && (
            <div className="SelectedOption">{predictedOption.title}</div>
          )}
          <div className="Confidence">
            <p>How confident are you?</p>
            <div className="ConfidenceSlider">
              <RangeSlider
                min={0}
                max={1}
                step={0.01}
                value={predictionConfidence}
                onChange={onChangePredictionConfidence}
                format={val => Math.round(val * 100)}
                tooltip={false}
              />
              <span>{Math.round(predictionConfidence * 100)}%</span>
            </div>
            <Button
              submitting={isSubmittingPrediction}
              disabled={isSubmittingPrediction}
              onClick={onCreatePrediction}
            >
              Submit
            </Button>
          </div>
          {!!predictionError && (
            <div>
              <p>{predictionError.message}</p>
            </div>
          )}
          <div>
            <TextLink onClick={onClearSelection}>Close</TextLink>
          </div>
        </div>
      </Modal>
    </div>
  )
}
