import React, { useMemo } from "react"

import { LoadingIndicatorPage, DividingTitle } from "../../../../components"
import { ScenarioList } from "../ScenarioList"
import { useCategory, useScenarioLists } from "../../hooks"
import { Link } from "gatsby"

interface CategoryDisplayProps {
  categoryId: string
}

export const CategoryDisplay = ({
  categoryId,
}: CategoryDisplayProps): JSX.Element => {
  const { isFetching, categoryData, categoryScenarios } = useCategory(
    categoryId
  )
  const { allLiveScenarios, resolvedScenarios } = useScenarioLists(
    categoryScenarios
  )

  return (
    <div className="ScenarioList">
      {!categoryData || isFetching ? (
        <LoadingIndicatorPage />
      ) : (
        <div className="wrapper">
          <h2>Category: {categoryData.title}</h2>
          <div style={{ textAlign: "left" }}>
            {allLiveScenarios.length > 0 && (
              <div style={{ marginBottom: 40 }}>
                <DividingTitle title="Live right now" />
                <ScenarioList
                  scenarios={allLiveScenarios}
                  showCategory={false}
                />
              </div>
            )}
            {resolvedScenarios.length > 0 && (
              <div style={{ marginBottom: 40 }}>
                <DividingTitle title="Recently resolved" />
                <ScenarioList
                  scenarios={resolvedScenarios}
                  showCategory={false}
                />
              </div>
            )}
          </div>
          <div style={{ margin: "25px 0" }}>
            <Link to="/">Back to homepage</Link>
          </div>
        </div>
      )}
    </div>
  )
}
