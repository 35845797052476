import React from "react"
import { Link } from "gatsby"
import "./ScenarioList.css"

import { Scenario } from "../../../../types.gen"
import { CategoryBadge } from "../CategoryBadge"

interface ScenarioListProps {
  scenarios: Scenario[]
  showCategory?: boolean
}

export const ScenarioList = ({
  scenarios,
  showCategory = true,
}: ScenarioListProps): JSX.Element => {
  return (
    <div className="ScenarioList">
      {scenarios.map(scenario => {
        return (
          <div className="ScenarioListItem" key={scenario.id}>
            <div>
              <Link to={`/scenario/${scenario.id}`}>{scenario.title}</Link>
            </div>
            {scenario.description && (
              <div className="ScenarioDescription">
                <small>{scenario.description}</small>
              </div>
            )}
            {showCategory && (
              <div>
                <CategoryBadge {...scenario.category} />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
